import Room01 from "../../Images/Room/room11.jpg";
import Room02 from "../../Images/Room/room7.jpg";
import Room03 from "../../Images/Rooms/ABP_8855.jpg";
import Room04 from "../../Images/Rooms/ABP_8588-Pano.jpg";


const RoomData = [
    {
        id:1,
        RoomName:"3BHK Premium Villa",
        Reviews:"440",
        ReviewsNumber:"3.9",
        Price:"100",
        OfferPerice:"89",
        RoomSize:"30-40",
        RoomMainImage:Room01,
    },
    {
        id:1,
        RoomName:"Classic Room",
        Reviews:"330",
        ReviewsNumber:"3.0",
        Price:"250",
        OfferPerice:"200",
        RoomSize:"30",
        RoomMainImage:Room02,
    },
    {
        id:1,
        RoomName:"Executive Room",
        Reviews:"210",
        ReviewsNumber:"2.7",
        Price:"290",
        OfferPerice:"250",
        RoomSize:"40-50",
        RoomMainImage:Room03,
    },
    {
        id:1,
        RoomName:"Deluxe Room",
        Reviews:"300",
        ReviewsNumber:"3.0",
        Price:"450",
        OfferPerice:"400",
        RoomSize:"50-60",
        RoomMainImage:Room04,
    },
];

export default RoomData;