import React, { useRef, useState } from 'react';
import "../Our Packages/Packages.css";
import "../Our Packages/PackagesCard.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import demo1 from "../../Images/Room/Room demo.jpg";
import { FaStar } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import royal from "../../Images/Aayush Images/royal.png";

const Packages = () => {
    return (
        <>
            <section className='Home-Section-packages'>
                <div className='Home-Section-packages-Head'>
                    <h2 className='animate__zoomIn'>Our Packages
                        <div className='HeadImageoverlay'>
                            <img src={royal} alt='' />
                        </div>
                    </h2>
                    <div className='Home-Section-packages-Head-para'>
                        <p >We Provide Top Class Facility <br></br> Expecially For You</p>
                    </div>
                </div>

                <div className='Home-Section-packages-Main-con'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[ Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='Home-Section-packages-card-body'>
                                <div className='Home-Section-packages-card-Image-con'>
                                    <img src={demo1} alt='' />
                                    <div className='Home-Section-Packages-Card-Details-con'>
                                        <h3>Premium Room</h3>
                                        <div className='Home-Section-Packages-Card-Details-Price-Days'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Capacity: 2 + 1</h4>
                                                    </div>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Area : 1600 Sqft.</h4>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-4'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Price:$155 Night</h4>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='Home-Packages-Card-Start-con'>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start'>
                                                <FaStar />
                                            </div>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore tenetur dolores excepturi, deleniti praesentium, nisi error obcaecati nesciunt aliquid nemo delectus. Autem maiores nisi atque dolore quas modi voluptates consequuntur.</p>
                                        <div className='Login-Btn'>
                                            <Link to="/RoomDetailsPage">
                                                <button>
                                                    Book Now
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='Home-Section-packages-card-body'>
                                <div className='Home-Section-packages-card-Image-con'>
                                    <img src={demo1} alt='' />
                                    <div className='Home-Section-Packages-Card-Details-con'>
                                        <h3>Premium Room</h3>
                                        <div className='Home-Section-Packages-Card-Details-Price-Days'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Capacity: 2 + 1</h4>
                                                    </div>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Area : 1600 Sqft.</h4>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-4'>
                                                    <div className='Home-Packages-Card-Info'>
                                                        <h4>Price:$155 Night</h4>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='Home-Packages-Card-Start-con'>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start starcolor'>
                                                <FaStar />
                                            </div>
                                            <div className='Home-Packages-Card-Start'>
                                                <FaStar />
                                            </div>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore tenetur dolores excepturi, deleniti praesentium, nisi error obcaecati nesciunt aliquid nemo delectus. Autem maiores nisi atque dolore quas modi voluptates consequuntur.</p>
                                        <div className='Login-Btn'>
                                            <Link to="/RoomDetailsPage">
                                                <button>
                                                    Book Now
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Packages