import Header from "../Component/Navbar/Header";
import "../Css/Policy.css";
import Footer from "../Component/Footer/FooterSec";
const Policy = () => {
  return (
    <>
      <Header />
      <section className="policy-Page-Section-Banner-Images">
        <div className="policy-Page-Section-Banner-Images-con">
          <h2>Hotel Policy & Booking Conditions</h2>
        </div>
      </section>
      <section className="section-policy">
        <div className="container">
          <div className="section-policy-main-con">
            <div className="section-policy-details-main-con">
              <h3>Hotel Policy</h3>
              <p>Check in time: 12PM</p>
              <p>Check out time: 10AM</p>
              <p>
                Quarantine protocols are being followed as per local government
                authorities
              </p>
              <p>Property staff is trained on hygiene guidelines</p>
              <p>
                Foreign Nationals are requested to carry scanned copies of the
                passport & visa as it is a statutory requirement (Mandatory for
                all foreign nationals). Please carry the original document as
                well for verification.
              </p>
              <p>
                For Indian nationals, valid photo identity proof along with an
                address by the central/state government will be acceptable
                (Driver’s License, Aadhar Card, Voter’s ID). All guests are
                requested to carry documents as mentioned{" "}
              </p>
              <p>
                Taxes are imposed by government authorities and are subject to
                change at the discretion of the government which may be
                collected at resort.
              </p>
              <p>Management reserves the right to refuse admission.</p>
              <p>
                For the GST invoice, kindly let us know at the time of
                confirmation with GST Certificate. Post check-out we are unable
                to make any changes to the bill.
              </p>
              <p>
                Outside food, beverages (alcoholic/ non-alcoholic) and hookah is
                strictly prohibited.
              </p>
              <p>
                Buffet setup will be subject to total occupancy of the resort,
                otherwise, the meals will be table served / TDH menu will be
                given to the guests.
              </p>
              <p>
                Jain Meals won't be available in Buffet for Individual bookings,
                Hence if any guest would require Jain meals, they would have to
                book on Continental Plan (Only Breakfast). Other Meals will be
                on Ala Carte Order.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Cancellation Policy</h3>
              <p>
                In Off Season Free Cancellation any time before 96 Hours prior
                to check in
              </p>
              <p>
                In case of cancellation after 96 Hours prior to check in, there
                will be no refund Preponement / Postponement can be granted if
                96 Hours prior to check in date (Valid up to 3 months) & subject
                availability & prevailing tariff.
              </p>
              <p>
                In Season & long weekend Free Cancellation any time before 168
                Hours prior to check in{" "}
              </p>
              <p>
                In case of cancellation after 168 Hours prior to check in, there
                will be no refund Preponement / Postponement can be granted if
                168 Hours prior to check in date (Valid up to 3 months) &
                subject availability & prevailing tariff.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Refund Terms</h3>
              <p>
                In a situation of cancellation and if the payment is made via a
                payment gateway (3rd Party), there shall be a deduction of 2.5%
                plus 18% GST (transaction charges) while refunding the paid
                amount. Applicable for bookings eligible for free cancellation.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Payment Terms</h3>
              <p>
                50% amount at the time of booking confirmation and balance 50%
                before 48 hours prior to check in time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
};

export default Policy;
