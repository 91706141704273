import React from 'react';
import "../Rooms/RoomFilter.css";
import RoomCard from './RoomCard';
import RoomData from "../RoomDetails/RoomData";

const RoomFilter = () => {
    return (
        <>
            <section className='Rooms-Page-Filter-Section'>
                <div className='container'>
                    {/* <div className='Rooms-Page-Filter-Section-con'>
                        <div className='Rooms-Page-Filter-Section-btn-con'>
                            <div className='row row-cols-md-5'>
                                <div className='col'>
                                    <div className='Rooms-Page-Filter-Section-btn'>
                                        <h2>Sort By:</h2>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='Rooms-Page-Filter-Section-btn'>
                                        <button>
                                            popular
                                        </button>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='Rooms-Page-Filter-Section-btn'>
                                        <button>
                                            User Rating
                                        </button>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='Rooms-Page-Filter-Section-btn'>
                                        <button>
                                            Price (Higest Price)
                                        </button>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='Rooms-Page-Filter-Section-btn'>
                                        <button>
                                            Price (Lowest Price)
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {
                        RoomData.map((room, index) => {
                            return (
                                <>
                                    <div className='Rooms-Page-Card-Filter-con'>
                                        <RoomCard key={index} roomData={room} />
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default RoomFilter