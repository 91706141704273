import React from 'react';
import "../Activities/ActivitiesSec.css";
import SwimmingPool from "../../Images/Activites/swim.png";
import royal from "../../Images/Aayush Images/royal.png";

import  chess from "../../Images/Activites/chess.png";
import carromboard from "../../Images/Activites/carrom-board-game.png";
import football from "../../Images/Activites/football.png";
import cricket from "../../Images/Activites/cricket.png";
import badminton from "../../Images/Activites/badminton.png";

const ActivitiesSec = () => {
    return (
        <>
            {/* <section className='Home-activities-section'>
                <div className='container'>
                    <div className='Home-activities-Section-Head'>
                        <h2>Activities</h2>
                    </div>
                    <div className='Home-Section-activities-details'>
                        <div className='row row-cols-md-8'>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={Entertainment}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Entertainment</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={Gym}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Gym</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={SwimmingPool}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Swimming Pool</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={KidsClub}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Kids Club</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={Tennis}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Tennis</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={Golf}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Golf</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='Home-Section-activities-icons-body'>
                                    <div className='Home-Section-Activities-Image-con'>
                                        <div className='Home-Section-Activities-Image'>
                                            <img src={Golf}/>
                                        </div>
                                    </div>
                                    <div className='Home-Section-Activities-Image-Name'>
                                        <h3>Golf</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='Home-activities-section-2'>
                <div className='Home-activities-Section-Head'>
                    <h2>
                        Activities
                        <div className='HeadImageoverlay'>
                            <img src={royal} alt='' />
                        </div>
                    </h2>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='Home-Section-activities-details-2'>
                                <div className='Home-Section-activities-2'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={chess} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Chess</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={carromboard} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Carrom Board</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={SwimmingPool} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Swimming Pool</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={football} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Football</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={cricket} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Cricket</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={badminton} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Badminton</h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-4'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={Golf} />
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Golf</h3>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            {/* <div className='Home-Section-activities-Side-Image'>
                                <img src={activitiesimage} alt="" />
                            </div> */}
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default ActivitiesSec;