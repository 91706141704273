import React, { useLayoutEffect, useRef, useState } from 'react';
// import 'animate.css';
import "../Css/Home.css";
import SearchBar from '../Component/Searchbar/SearchBar';
import HomeAbout from '../Component/About/HomeAbout';
import HomeRooms from '../Component/HomeRoom/HomeRooms';
import ActivitiesSec from '../Component/Activities/ActivitiesSec';
import AmenitiesSec from '../Component/Amenities/AmenitiesSec';
import Packages from '../Component/Our Packages/Packages';
import CounterSec from '../Component/Counter/CounterSec';
import Attraction from '../Component/Nearby/Attraction';
import BlogSec from '../Component/Blog/BlogSec';
import FooterSec from '../Component/Footer/FooterSec';
import { Link } from 'react-router-dom';
import Header from '../Component/Navbar/Header';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import demo1 from "../Images/Rooms/ABP_8588-Pano.jpg";
import bannerImage01 from "../Images/Resort/resort11.jpg";
// import bannerImage01 from "../Images/Aayush Images/ABP_9244.jpg";
import bannerImage02 from "../Images/View/sunrise.jpg";
import bannerImage03 from "../Images/Pool/pool10.jpg";
import bannerImage05 from "../Images/Aayush Wedding/Wedding Aayush Resort.jpg";


const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='Section-Home-banner'>
                <Header />
                <div className='demo'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage01})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h1 className='animate__animated animate__backInDown'>Welcome To <br></br> Royal Resort</h1>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/Rooms">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage02})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h1 className='animate__animated animate__backInDown'>Welcome To <br></br> Royal Resort</h1>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/Rooms">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage03})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h1 className='animate__animated animate__backInDown'>Welcome To <br></br> Royal Resort</h1>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/Rooms">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            {/* <SearchBar /> */}
            <HomeAbout />
            <Packages />
            {/* <HomeRooms /> */}
            <ActivitiesSec />
            <AmenitiesSec />
            <CounterSec />
            <Attraction />
            {/* <BlogSec /> */}

            <FooterSec />
        </>
    )
}

export default Home