import React, { useLayoutEffect } from 'react';
import "../Css/Rooms.css";
import "../Component/Navbar/Header";
import BannerBar from '../Component/SearchbarSecond/BannerBar';
import RoomFilter from '../Component/Rooms/RoomFilter';
import Footer from "../Component/Footer/FooterSec";
import Header from "../Component/Navbar/Header";

const Rooms = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Header />
            <section className='Rooms-Section-Banner-List-Images'>
                <div className='Rooms-Section-Banner-Images-con'>
                    {/* <h2>Room Search Results</h2> */}
                </div>
            </section>
            {/* <BannerBar /> */}
            <RoomFilter />
            <Footer />
        </>
    )
}

export default Rooms