import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// Logo
import Logo from "../../Images/Logo/Royal Resort Logo.png";
///////
import "../Navbar/Header.css";
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#">
                        <div className='Logo-Con'>
                            <img src={Logo} alt="" />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1"><Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="#action2"><Link to="/Rooms">Rooms</Link></Nav.Link>
                            <Nav.Link href="#action2"><Link to="/About">About us</Link></Nav.Link>
                            <Nav.Link href="#action2"><Link to="/Gallery">Gallery</Link></Nav.Link>
                            {/* <Nav.Link href="#action2"><Link to="/Blog">Blog</Link></Nav.Link> */}
                            <Nav.Link href="#action2"><Link to="/Contact">Contact us</Link></Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <div className='Login-Btn'>
                                <Link to="/sign-in">
                                    <button>
                                        Login
                                    </button>
                                </Link>
                            </div>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header