import React, { useRef, useState } from 'react';
import "../Nearby/Attraction.css";

import WinterHiking from "../../Images/Winter Hiking.jpg";
import birdsanctuary from "../../Images/bird sanctuary.JPG";
import lakeview from "../../Images/lake view.jpg";
import paragliding from "../../Images/paragliding images.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

import ParsiPoint from "../../Images/Near Attraction Points/Parsi-Point.jpg";
import MahabaleshwarMarket from "../../Images/Near Attraction Points/Mahabaleshwar-Market.jpg";
import VelocityGamingZone from "../../Images/Near Attraction Points/Velocity-Gaming- Zone.png";
import VennaLakeBoatingPoint from "../../Images/Near Attraction Points/Venna-Lake-Boating-Point.jpg";
import MaproGarden from "../../Images/Near Attraction Points/Mapro-Garden.jpg";
import LingmalaWaterfalls from "../../Images/Near Attraction Points/Lingmala-Waterfalls.jpg";
import KatesPoint from '../../Images/Near Attraction Points/Kates-Point.jpg';
const Attraction = () => {
    return (
        <>
            <section className='Home-Section-Attraction'>
                <div className='container'>
                    <div className='Home-Section-Attraction-Head'>
                        <h2>Near Attraction Points</h2>
                    </div>

                    <div className='Home-Section-Attraction-Details-con'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con'>
                                    <img src={ParsiPoint} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            9.5 Kms
                                        </h3>

                                        <h4>
                                            Parsi Point
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con Attractioncarddownstyle'>
                                    <img src={MahabaleshwarMarket} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            8.5 Kms
                                        </h3>

                                        <h4>
                                            Mahabaleshwar Market
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con'>
                                    <img src={VelocityGamingZone} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            6 Kms
                                        </h3>

                                        <h4>
                                            Velocity Gaming Zone
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con Attractioncarddownstyle'>
                                    <img src={VennaLakeBoatingPoint} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            6 Kms
                                        </h3>

                                        <h4>
                                            Venna Lake Boating Point
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con'>
                                    <img src={MaproGarden} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            4 Kms
                                        </h3>

                                        <h4>
                                            Mapro Garden
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con Attractioncarddownstyle'>
                                    <img src={LingmalaWaterfalls} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            3 Kms
                                        </h3>

                                        <h4>
                                            Lingmala Waterfalls
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Home-Section-Attraction-Card-Con'>
                                    <img src={KatesPoint} />
                                    <div className='Home-Section-Attraction-Card-overlay'>
                                        <h3>
                                            3 Kms
                                        </h3>

                                        <h4>
                                            Kate's Point
                                        </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Attraction