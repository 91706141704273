import React from 'react';
import "../Password/ForgotPass.css";
import { Link } from 'react-router-dom';


const ForgotPass = () => {
    return (
        <>
            <section className='Login-Section-Con'>
                <div className='container'>
                    <div className='Login-Page-Main-Con'>
                        <div className='Login-Page-Box'>
                            <div className='Login-Page-Head-con'>
                                <h2>Forgot password</h2>
                            </div>
                            <div className='Login-Input-Con'>
                                <div className='Login-Input'>
                                    <div class="Contact-Page-Form-Inputs">
                                        <label for="fname">Email-id</label>
                                        <div class="Like-to-pay-form-con-Card-Info-Input">
                                            <input type="text" id="fname" name="fname" placeholder="Enter your Email-id" />
                                        </div>
                                    </div>
                                    <div className='Login-Page-Sign-btn'>
                                        <Link to="/Login">
                                            <button>
                                                Reset password
                                            </button>
                                        </Link>
                                    </div>
                                    <div className='Login-Google-Sign-up'>
                                        <h4>Already have an account <span><Link to="/Login">Login</Link></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPass