import React, { useState, useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Component/GalleryData';
import Bounce from 'react-reveal/Bounce';
import Footer from '../Component/Footer/FooterSec';
import { MetaTags } from 'react-meta-tags';
import Header from "../Component/Navbar/Header";

const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>
<Header/>
            {/* <MetaTags>
                <title>Kitchen Interior Designer In Thane | Unico Interior Design</title>
                <meta title="Kitchen Interior Designer In Thane | Unico Interior Design" />
                <meta
                    name="description"
                    content="Looking For Kitchen Interior Designer In Thane? Look Nofurther Unico Interior Design Is Here To Make Your Kitchen More Speciaous. "
                />
                <meta
                    name="keywords"
                    content="interior designers in thane, top interior designer in thane, home interior designers in thane, kitchen interior designer in thane, top bedroom interior designer in thane, Living Room Designer In Thane"
                />
                <link rel="canonical" href="https://unicointeriordesign.com/kitchen-interior-designer-in-thane" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Kitchen Interior Designer In Thane | Unico Interior Design" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://unicointeriordesign.com/" />
                <meta property="og:description" content="Looking For Kitchen Interior Designer In Thane? Look Nofurther Unico Interior Design Is Here To Make Your Kitchen More Speciaous. " />
                <meta property="og:image" content="https://unicointeriordesign.com/static/media/Logo%202.90c4e96007834f14ab98.png" />
            </MetaTags> */}

            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

            <section className='Gallery-Page-Section-Banner-Images'>
                <div className='Gallery-Page-Section-Banner-Images-con'>
                    <h2>Gallery</h2>
                </div>
            </section>

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery