import logo from './logo.svg';
import './App.css';
import Header from './Component/Navbar/Header';
import Home from './Pages/Home';
import About from "./Pages/About";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomeAbout from './Component/About/HomeAbout';
import Rooms from './Pages/Rooms';
import Blog from './Pages/Blog';
import ReviewBooking from './Component/BookingReview/ReviewBooking';
import LikePay from './Component/Pay/LikePay';
import Contact from './Pages/Contact';
import Login from './Component/account/SignIn';
import SignUp from './Component/account/SignUp';
import Gallery from './Pages/Gallery';
import ForgotPass from './Component/Password/ForgotPass';
import BookingConfirm from './Component/Congratulation/BookingConfirm';
import RoomDetailsPage from './Component/RoomDetails/RoomDetailsPage';
import Policy from './Pages/Policy';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Rooms' element={<Rooms />} />
          <Route path='/About' element={<About />} />
          <Route path='/Gallery' element={<Gallery />} />
          <Route path='/Blog' element={<Blog />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/booking' element={<ReviewBooking />} />
          <Route path='/pay' element={<LikePay />} />
          <Route path='/sign-in' element={<Login />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/forgotpassword' element={<ForgotPass />} />
          <Route path='/BookingConfirm' element={<BookingConfirm />} />
          <Route path='/RoomDetailsPage' element={<RoomDetailsPage />} />
          <Route path='/policy' element={<Policy/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
