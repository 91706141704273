import React from 'react';
import "../Amenities/AmenitiesSec.css";

import cctv from "../../Images/Amenitie/cctv.png";
import security from "../../Images/Amenitie/guard.png";
import parking from '../../Images/Amenitie/parking.png';
import gamezone from "../../Images/Amenitie/games.png";
import { FaCheckDouble } from "react-icons/fa6";
import royal from "../../Images/Aayush Images/royal.png";
const AmenitiesSec = () => {
    return (
        <>
            <section className='Home-Section-Amenities-con'>
                <div className='container'>
                    <div className='Home-Aminities-Section-Head'>
                        <h2>
                            Aminities
                            <div className='HeadImageoverlay'>
                                <img src={royal} alt='' />
                            </div>
                        </h2>
                    </div>
                    <div className='Home-Section-Amenities-Main-Con'>
                        {/* <div className='row'>
                            <div className='col-md-3'>
                                <div className='Home-Section-Amenities-Card-body'>
                                    <img src={swimmingpool} alt='swimming pool' />
                                    <div className='Home-Section-Amenities-Card-overlay'>
                                        <h3>Swimming pool</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Home-Section-Amenities-Card-body'>
                                    <img src={Gym} alt='swimming pool' />
                                    <div className='Home-Section-Amenities-Card-overlay'>
                                        <h3>Gym</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Home-Section-Amenities-Card-body'>
                                    <img src={DailyHousekeeping} alt='swimming pool' />
                                    <div className='Home-Section-Amenities-Card-overlay'>
                                        <h3>Daily Housekeeping</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Home-Section-Amenities-Card-body'>
                                    <img src={FreeParking} alt='swimming pool' />
                                    <div className='Home-Section-Amenities-Card-overlay'>
                                        <h3>Free Parking</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Home-Section-Amenities-icon-name-con'>
                                    <div className='Home-Section-amenities-icon-details-con'>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={cctv} />
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>CCTV</h3>
                                            </div>
                                        </div>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={security} />
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Security</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='Home-Section-Amenities-icon-name-con'>
                                    <div className='Home-Section-amenities-icon-details-con'>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={parking} />
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Secure Parking</h3>
                                            </div>
                                        </div>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={gamezone} />
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Game Zone</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='Home-Section-Aminites-Details-con'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur suscipit, aspernatur optio minima recusandae placeat quibusdam facilis nemo numquam laudantium tempora obcaecati. Quam, ullam! Eaque maxime exercitationem fuga culpa facere porro, a quasi dignissimos, incidunt laudantium quos magni voluptas voluptatum.</p>
                                    <div className='Home-Section-Aminites-Details-point-con'>
                                        <div className='row row-cols-lg-2 row-cols-sm-2 row-cols-1'>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>ON-CALL MEDICAL SERVICE</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>TELEVISION WITH HD SET TOP BOX</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>AIR CONDITION (AC)</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>GAME ROOM</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>SOFA</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>TEA/COFFEE MAKER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>PACKAGE DRINKING WATER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>BATHROOM AND TOILETS WITH ALL SUPPLIES & AMENITIES & AMINITIES</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>HOT/COLD WATER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>MUSIC SYSTEM</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>AN INVERTER BACKUP</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>WIFI@NO COST</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AmenitiesSec