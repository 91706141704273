import React, { useState } from "react";
import "../RoomDetails/RoomDetailsPage.css";
import { FaWifi } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { FaPersonSwimming } from "react-icons/fa6";
import { RiFridgeLine } from "react-icons/ri";
import { BsFillSafe2Fill } from "react-icons/bs";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import Footer from "../../Component/Footer/FooterSec";
import Header from "../Navbar/Header";
import { Link } from "react-router-dom";

import Room from "../../Images/Room/Room1.jpg";
import Room01 from "../../Images/Room/dining area 2.jpg";
import Room02 from "../../Images/Room/hall1.jpg";
import Room03 from "../../Images/Room/resort balcony.jpeg";
import Room04 from "../../Images/Room/room9.JPG";

import { DatePicker, Progress } from "antd";
import moment from "moment/moment";
import { useBooking } from "../../context/BookingProvider";
const RoomDetailsPage = () => {
  const [availableRooms, setAvailableRooms] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    adultQuantity,
    setAdultQuantity,
    childrenQuantity,
    setChildrenQuantity,
    roomsQuantity,
    setRoomsQuantity,
  } = useBooking();

  // const [childrenQuantity, setChildrenQuantity] = useState(() => {
  //     const storedValue = getLocalStorageItem('booking_childrenQuantity');
  //     return storedValue ? parseInt(storedValue) : 0;
  // });

  // const [adultQuantity, setAdultQuantity] = useState(() => {
  //     const storedValue = localStorage.getItem('booking_adultQuantity');
  //     return storedValue ? parseInt(storedValue) : 0;
  // });

  // const [roomsQuantity, setRoomsQuantity] = useState(() => {
  //     const storedValue = localStorage.getItem('booking_roomsQuantity');
  //     return storedValue ? parseInt(storedValue) : 1;
  // });

  // const [checkInDate, setCheckInDate] = useState(() => {
  //     const storedValue = localStorage.getItem('booking_checkInDate');
  //     return storedValue ? new Date(storedValue) : null;
  // });

  // const [checkOutDate, setCheckOutDate] = useState(() => {
  //     const storedValue = localStorage.getItem('booking_checkOutDate');
  //     return storedValue ? new Date(storedValue) : null;
  // });

  const handleChildrenIncrement = () => {
    setChildrenQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleChildrenDecrement = () => {
    if (childrenQuantity > 1) {
      setChildrenQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAdultIncrement = () => {
    setAdultQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleAdultDecrement = () => {
    if (adultQuantity > 1) {
      setAdultQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleRoomsIncrement = () => {
    setRoomsQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleRoomsDecrement = () => {
    if (roomsQuantity > 1) {
      setRoomsQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  // const handleBooking = () => {
  //     const values = {
  //         childrenQuantity,
  //         adultQuantity,
  //         roomsQuantity,
  //         checkInDate,
  //         checkOutDate,
  //     };
  //     setLocalStorageItem('booking_selectedValues', values);
  // };

  const API_URL = "https://riverestates.in/api/abilitycheck.php";
  const handleBooking = async () => {
    const formData = new FormData();
    formData.append("start_date", formatDate(checkInDate));
    formData.append("end_date", formatDate(checkOutDate));
    formData.append("action", "availableroom");
    formData.append("token", "checkOuE2ZCdq9VrVvLDOQGlPT0nj2r6TKTZGevR26tDate");
    try {
      setLoading(true);
      const response = await fetch(API_URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const data = await response.json();
      setAvailableRooms(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const disabledCheckInDate = (current) => {
    // Disable past dates and dates after the selected check-out date
    return (
      current &&
      (current < moment().startOf("day") ||
        (checkOutDate && current > checkOutDate))
    );
  };

  const disabledCheckOutDate = (current) => {
    // Disable past dates and dates before the selected check-in date
    return (
      current &&
      (current < moment().startOf("day") ||
        (checkInDate && current <= checkInDate))
    );
  };
  const handleCheckInChange = (date, dateString) => {
    setCheckInDate(date);
    if (moment(date).isAfter(checkOutDate)) {
      setCheckOutDate(null);
    }
  };

  const handleCheckOutChange = (date, dateString) => {
    setCheckOutDate(date);
  };

  return (
    <>
      <Header />
      <section className="Rooms-Section-Banner-Images">
        <div className="Rooms-Section-Banner-Images-con">
          <h2>Room Search Results</h2>
        </div>
      </section>
      {/* <BannerBar /> */}
      <div className="container">
        <div className="top-bottom">
          <div className="row">
            <div className="col text-right">
              <div className="Room-Details-Head-Main-con">
                <h2>3BHK Premium Villa</h2>
                <p style={{ color: "orange" }}>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar />
                  <FaStar />{" "}
                </p>
              </div>
            </div>
            <div className="col text-left">
              <div className="Room-Details-Head-Details-con">
                <p className="Room-Details-Page-Card-Main-Price">$300/Night</p>
                <h4>$300/Night</h4>
                {/* <div className="Login-Btn">
                                    <Link to="/booking">
                                        <button>Book this Room</button>
                                    </Link>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="imagesectionn">
          <div className="row">
            <div className="col-6">
              <div className="Image-Section-Room-Details-con">
                <img className="image-frst" src={Room} alt="" />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="Image-Section-Room-Details-con-Second">
                    <img className="image-frst" src={Room01} alt="" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="Image-Section-Room-Details-con-Second">
                    <img className="image-frst" src={Room02} alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="Image-Section-Room-Details-con-third">
                    <img className="image-frst" src={Room03} alt="" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="Image-Section-Room-Details-con-third">
                    <img className="image-frst" src={Room04} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bottom">
          <div className="row">
            <div className="col-6">
              <div className="Room-Details-Page-Description">
                <h3>Description</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius
                  officia aspernatur maxime harum vitae doloremque consequatur
                  porro, eum molestias non vel modi suscipit similique, labore
                  reiciendis. Quidem est assumenda dolore. Lorem ipsum dolor sit
                  amet consectetur, adipisicing elit. Cumque nulla repellat,
                  dolor rem officia recusandae ipsam odio? Officiis molestias
                  necessitatibus, nemo ipsa laboriosam aspernatur libero aliquam
                  asperiores quae modi quo. Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Sapiente, cupiditate iste autem
                  quas iure similique libero! Numquam, earum molestiae facere
                  nam delectus sed eos corrupti. Quos odit aliquid amet
                  laudantium. Lorem ipsum dolor, sit amet consectetur
                  adipisicing elit. Ea debitis omnis quaerat atque cumque? Rem
                  rerum sequi doloremque quas, blanditiis delectus dolorum dolor
                  voluptate dignissimos excepturi, et officia ut cupiditate.
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem,
                  hic voluptatem? Ullam ut, ex recusandae pariatur, et eos
                  perspiciatis quo exercitationem dicta necessitatibus
                  praesentium molestiae voluptas, accusantium magni quibusdam
                  nulla!
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="Room-Details-Page-Description">
                <h3>Room Aminity</h3>
                <div className="row">
                  <div className="col-6">
                    <div className="ul styleul">
                      <li className="iconsection">
                        <span className="serviceicon">
                          <TbAirConditioning />
                        </span>
                        A/C
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <FaPersonSwimming />
                        </span>
                        Swiming Pool
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <RiFridgeLine />
                        </span>
                        Fridge
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <BsFillSafe2Fill />
                        </span>
                        Safe Box
                      </li>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="ul styleul">
                      <li className="iconsection">
                        <span className="serviceicon">
                          <FaWifi />
                        </span>
                        Wifi
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <PiTelevisionSimpleBold />
                        </span>
                        T.v
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bottom">
          <div className="row">
            {/* <div className="col-6">
                            <div className='Room-Details-Page-Description'>
                                <h3>Review</h3>
                                <div className='rating-first'>
                                    <p className='rating-text' style={{ color: "white" }}>4.8</p>
                                    <h4 className='rating-text'>very good</h4>
                                    <div className="Room-Details-Star-con" style={{ color: "orange" }} >
                                        <FaStar /> <FaStar /> <FaStar /> <FaStar />
                                    </div>
                                </div>
                                <div className='rating-Progress-bar-con'>
                                    <div className='Rating-Progress-Bar'>
                                        <h4>Accommodation</h4>
                                        <Progress percent={60} />
                                    </div>
                                    <div className='Rating-Progress-Bar'>
                                        <h4>Location</h4>
                                        <Progress percent={70} />
                                    </div>
                                    <div className='Rating-Progress-Bar'>
                                        <h4>Meals</h4>
                                        <Progress percent={80} />
                                    </div>
                                    <div className='Rating-Progress-Bar'>
                                        <h4>Facilities</h4>
                                        <Progress percent={90} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="col-6">
              <div className="Room-Details-Facility-head">
                <h3>Our Facility</h3>
              </div>
              <div className="row">
                <div className="col-6">
                  <ul className="liststyle-review">
                    <li>Kettle, tea & coffee</li>
                    <li>Comfortable beds</li>
                    <li>Free Wi-fi</li>
                    <li>Air conditione</li>
                    <li>Breakfast included</li>
                    <li>Wardrobe</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="liststyle-review">
                    <li>Bathrobe & slippers</li>
                    <li>Balcony or terrace</li>
                    <li>Safe</li>
                    <li>Shower bathtub</li>
                    <li>Working table</li>
                    <li>Shampoo and soap</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Room-Details-Form">
                <form>
                  <div className="Form-Date-Piker">
                    <div className="form-group">
                      <label htmlFor="fullName">Check In</label>
                      <DatePicker
                        format="DD MMM YYYY"
                        onChange={handleCheckInChange}
                        value={checkInDate}
                        disabledDate={disabledCheckInDate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="fullName">Check Out</label>
                      <DatePicker
                        format="DD MMM YYYY"
                        onChange={handleCheckOutChange}
                        value={checkOutDate}
                        disabledDate={disabledCheckOutDate}
                      />
                    </div>
                  </div>

                  <div className="FormData-Person-Select">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-6">
                        <div className="inputfiled">
                          <h6 className="leftclassname">Adults</h6>
                          <div className="containerer">
                            <div className="quantity">
                              <div
                                className="quantity__minus"
                                onClick={handleAdultDecrement}
                              >
                                <span>-</span>
                              </div>
                              <input
                                name="adultQuantity"
                                type="text"
                                className="quantity__input"
                                value={adultQuantity}
                                readOnly
                              />
                              <div
                                className="quantity__plus"
                                onClick={handleAdultIncrement}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-lg-4 col-md-6 col-sm-4 col-xs-6'>
                                                <div className='inputfiled'>
                                                    <h6 className="leftclassname">Children</h6>
                                                    <div className='containerer'>
                                                        <div className="quantity">
                                                            <div className="quantity__minus" onClick={handleChildrenDecrement}><span>-</span></div>
                                                            <input name="childrenQuantity" type="text" className="quantity__input" value={childrenQuantity} readOnly />
                                                            <div className="quantity__plus" onClick={handleChildrenIncrement}><span>+</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-6">
                        <div className="inputfiled">
                          <h6 className="leftclassname">Rooms</h6>
                          <div className="containerer">
                            <div className="quantity">
                              <div
                                className="quantity__minus"
                                onClick={handleRoomsDecrement}
                              >
                                <span>-</span>
                              </div>
                              <input
                                name="roomsQuantity"
                                type="text"
                                className="quantity__input"
                                value={roomsQuantity}
                                readOnly
                              />
                              <div
                                className="quantity__plus"
                                onClick={handleRoomsIncrement}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="CheckAvailability">
                    <Link to="/booking">
                      <button>Book Now</button>
                    </Link>
                  </div>

                  {/* <div className="CheckAvailability">
                    {availableRooms !== null ? (
                      availableRooms.rooms.length > 0 ? (
                        <Link to="/booking">
                          <button>Book Now</button>
                        </Link>
                      ) : (
                        <>
                          <p style={{ color: "red" }}>
                            No rooms available for the selected dates.
                          </p>
                          <button onClick={handleBooking} disabled={loading}>
                            {loading
                              ? "Checking Availability..."
                              : "Check Availability"}
                          </button>
                        </>
                      )
                    ) : (
                      <button onClick={handleBooking} disabled={loading}>
                        {loading
                          ? "Checking Availability..."
                          : "Check Availability"}
                      </button>
                    )}
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="Room-Details-Page-Description">
                <h3>Review</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel
                  totam similique ipsum, aspernatur rem ullam veritatis ipsa
                  accusantium voluptas minima facilis beatae ut esse accusamus
                  vitae? Officiis alias commodi ad? Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Placeat architecto nulla
                  accusamus voluptates vel laudantium beatae quibusdam optio,
                  porro nisi vitae ad similique veritatis odit libero assumenda
                  reprehenderit sequi modi! Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Libero tenetur odit officiis
                  error quasi vel corrupti illum dicta consequuntur, quisquam
                  fuga rem quaerat at alias animi dignissimos praesentium
                  aspernatur recusandae!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
                    <div className="row">
                        <div className="col-12">
                            <div className='Room-Details-Plan-Head-con'>
                                <h3>Room plans</h3>
                            </div>
                        </div>

                        <div className='Room-Details-Plan-Tabs'>
                            <div className="row">
                                <div className="col-2">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'> Room only</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'> With breakfast Included</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'>Breakfast & Linch Included</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='filterroom'>
                                        <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                        <span className='paddinf10'>Custom</span>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='filterroomsection'>
                            <div className='Room-Details-Plan-Info-Head-con'>
                                <h4>
                                    Room plaing
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Room-Details-Plan-Info-con'>
                                        <ul>
                                            <li>
                                                No meals included
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                            <li>
                                                20% discount on food and soft Beverages
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='left-sideprce'>
                                        <p>pernight</p>
                                        <h3>$340</h3>
                                        <p>+120 tax & fees</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>

      <Footer />
    </>
  );
};

export default RoomDetailsPage;
