import React from 'react';
import "../Css/Blog.css";
import Footer from "../Component/Footer/FooterSec";
import demo from "../Images/About/9T0A0365.JPG";
import demo1 from "../Images/About/9T0A0367.JPG";
import Header from "../Component/Navbar/Header";

const Blog = () => {
    return (
        <>
            <Header />
            <section className='Blog-Page-Section-Banner-Images'>
                <div className='Blog-Page-Section-Banner-Images-con'>
                    <h2>Blog</h2>
                </div>
            </section>

            <section className='Blog-Page-Section-Popular-Con'>
                <div className='container'>
                    <div className='Blog-Page-Section-Popular'>
                        <div className='Blog-Page-Section-Popular-Head'>
                            <h3>Popular Articles</h3>
                            <button>
                                View all
                            </button>
                        </div>
                        <div className='Blog-Page-Section-Popular-Details-con'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='Blog-Page-Section-Popular-Details-Info-con'>
                                        <div className='Blog-Page-Section-Popular-Details-Info-Image'>
                                            <img src={demo} alt='' />
                                            <div className='Blog-Page-Section-Popular-Details-Info-overflow'>
                                                <div className='Blog-Page-Section-Popular-Details-Info'>
                                                    <div className='row'>
                                                        <div className='col-md-9'>
                                                            <div className='Blog-Page-Section-Popular-Details-Info-Date'>
                                                                <h4>22 December 2024</h4>
                                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis fugiat optio reprehenderit porro. Atque, fugit in perspiciatis error pariatur adipisci.</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className='Blog-Page-Section-Popular-Details-Info-btn'>
                                                                <button>
                                                                    Read More
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='Blog-Page-Section-Popular-Details-Info-con'>
                                        <div className='Blog-Page-Section-Popular-Details-Info-Image'>
                                            <img src={demo1} alt='' />
                                            <div className='Blog-Page-Section-Popular-Details-Info-overflow'>
                                                <div className='Blog-Page-Section-Popular-Details-Info'>
                                                    <div className='row'>
                                                        <div className='col-md-9'>
                                                            <div className='Blog-Page-Section-Popular-Details-Info-Date'>
                                                                <h4>22 December 2024</h4>
                                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis fugiat optio reprehenderit porro. Atque, fugit in perspiciatis error pariatur adipisci.</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className='Blog-Page-Section-Popular-Details-Info-btn'>
                                                                <button>
                                                                    Read More
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className='Blog-Page-Section-Popular-Con'>
                <div className='container'>
                    <div className='Blog-Page-Section-Popular'>
                        <div className='Blog-Page-Section-Popular-Head'>
                            <h3>Resent Articles</h3>
                            <button>
                                View all
                            </button>
                        </div>
                        <div className='Blog-Page-Section-Popular-Details-con'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='Blog-Page-Section-Popular-Details-Info-con'>
                                        <div className='Blog-Page-Section-Popular-Details-Info-Image-Second'>
                                            <img src={demo} alt='' />
                                            <div className='Blog-Page-Section-Popular-Details-Info-overflow-Second'>
                                                <div className='Blog-Page-Section-Popular-Details-Info'>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-Date'>
                                                        <h4>22 December 2024</h4>
                                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis fugiat optio reprehenderit porro. Atque, fugit in perspiciatis error pariatur adipisci.</p>
                                                    </div>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-btn'>
                                                        <button>
                                                            Read More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='Blog-Page-Section-Popular-Details-Info-con'>
                                        <div className='Blog-Page-Section-Popular-Details-Info-Image-Second'>
                                            <img src={demo1} alt='' />
                                            <div className='Blog-Page-Section-Popular-Details-Info-overflow-Second'>
                                                <div className='Blog-Page-Section-Popular-Details-Info'>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-Date'>
                                                        <h4>22 December 2024</h4>
                                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis fugiat optio reprehenderit porro. Atque, fugit in perspiciatis error pariatur adipisci.</p>
                                                    </div>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-btn'>
                                                        <button>
                                                            Read More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='Blog-Page-Section-Popular-Details-Info-con'>
                                        <div className='Blog-Page-Section-Popular-Details-Info-Image-Second'>
                                            <img src={demo1} alt='' />
                                            <div className='Blog-Page-Section-Popular-Details-Info-overflow-Second'>
                                                <div className='Blog-Page-Section-Popular-Details-Info'>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-Date'>
                                                        <h4>22 December 2024</h4>
                                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis fugiat optio reprehenderit porro. Atque, fugit in perspiciatis error pariatur adipisci.</p>
                                                    </div>
                                                    <div className='Blog-Page-Section-Popular-Details-Info-btn'>
                                                        <button>
                                                            Read More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Blog