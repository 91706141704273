import React from 'react';
import "../About/HomeAbout.css";

import about1 from "../../Images/Resort/resort11.jpg";
import about2 from "../../Images/View/sunrise.jpg";
import { Link } from 'react-router-dom';

const HomeAbout = () => {
  return (
    <>
      <section className="offer-area home-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="section-title home-two"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <h4>Exclusive Agency</h4>
                <h1>A Welcoming Haven for All Kind of<br />Travelers and Nature Lovers</h1>

                <p className="section-desc home-two">
                  A wonderful serenity has taken possession of my entire soul, like
                  these royella dolor amet, consectetur adipiscing elit dos eiusmod
                  tempor incididunt resort sweet spring which I enjoy with my whole
                  heart.
                </p>
              </div>
              <div
                className="offer-thumb home-two Image-con"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <img src={about1} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="offer-thumb home-two upper Image-con"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <img src={about2} alt="" />
              </div>
              <div
                className="section-title home-two"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <p className="section-desc home-two">
                  A wonderful serenity has taken possession of my entire soul, like
                  these royella dolor amet, consectetur adipiscing elit dos eiusmod
                  tempor incididunt resort sweet spring which I enjoy with my whole
                  heart.
                </p>
              </div>
              <div className="luxury-button home-two" data-cue="zoomIn">
                <Link to="/About">See More Tour</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='Home-Section-Aboutus'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='Home-Section-Aboutus-Info-con'>
                                <div className='Home-Section-Aboutus-Info'>
                                    <h2>
                                        Welcome <br></br>to the Resort
                                    </h2>
                                    <p>
                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque quo molestiae asperiores blanditiis commodi eveniet assumenda iste, rem quas ex!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='Home-Section-aboutus-Images-con'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='Home-Section-About-Images1'>
                                            <div className='Home-Section-About-Images-main-con1 HomeAboutimage01'>
                                                <img src={About01} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='Home-Section-About-Images2'>
                                            <div className='Home-Section-About-Images-main-con2 HomeAboutimage02'>
                                                <img src={About02} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='Home-Section-About-Images1'>
                                            <div className='Home-Section-About-Images-main-con1 home-About-Section-cardimage02'>
                                                <img src={About03} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='Home-Section-About-Images2'>
                                            <div className='Home-Section-About-Images-main-con2 home-About-Section-cardimage01'>
                                                <img src={About05} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section> */}
    </>
  )
}

export default HomeAbout