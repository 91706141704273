import React from 'react';
import "../BookingReview/ReviewBooking.css";
import { FaStar } from "react-icons/fa6";
import RoomImage from "../../Images/About/9T0A0397.JPG";
import { FaPlus } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import FooterSec from '../Footer/FooterSec';
const ReviewBooking = () => {
  return (
    <>
      <section className='Review-Booking-Section-Con'>
        <div className='container'>
          <div className='Review-Booking-Section-Head'>
            <h2>Review Your Booking</h2>
          </div>
          <div className='Review-Booking-Section-Details-con'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='Review-Booking-Section-Details-Info-Main-con'>
                  <div className='Review-Booking-Section-Details-Info-container'>
                    <div className='Review-Booking-Section-Details-Name-Star'>
                      <h3>Classic Room</h3>
                      <div className='Review-Booking-Section-Details-Star-con'>
                        <div className='Review-Booking-Section-Details-Star starcolor'>
                          <FaStar />
                        </div>
                        <div className='Review-Booking-Section-Details-Star starcolor'>
                          <FaStar />
                        </div>
                        <div className='Review-Booking-Section-Details-Star starcolor'>
                          <FaStar />
                        </div>
                        <div className='Review-Booking-Section-Details-Star starcolor'>
                          <FaStar />
                        </div>
                        <div className='Review-Booking-Section-Details-Star'>
                          <FaStar />
                        </div>
                      </div>
                    </div>
                    <div className='Review-Booking-Section-Details-Image-con'>
                      <img src={RoomImage} alt='' />
                    </div>
                  </div>
                  <div className='Review-Booking-Section-Day-People-Details-con'>
                    <div className='Review-Booking-Section-Day-Details-con'>
                      <div className='Review-Booking-Section-Check-Details'>
                        <h4>Check-In</h4>
                        <h5>Fri, 22 Dec 2023</h5>
                      </div>
                      <div className='Review-Booking-Section-Check-Details Review-Booking-Section-Check-Details-margin'>
                        <h4>Check-In</h4>
                        <h5>Fri, 22 Dec 2023</h5>
                      </div>
                    </div>
                    <div className='Review-Booking-Section-People-Details-con'>
                      <div className='Review-Booking-Section-People-Details'>
                        <h6>5 Night</h6>
                      </div>
                      <div className='Review-Booking-Section-People-Details'>
                        <h6>1 Adult</h6>
                      </div>
                      <div className='Review-Booking-Section-People-Details'>
                        <h6>1 Childern</h6>
                      </div>
                      <div className='Review-Booking-Section-People-Details'>
                        <h6>1 Room</h6>
                      </div>
                    </div>
                  </div>
                  <div className='Review-Booking-Section-Instruction-con'>
                    <ul>
                      <li>
                        Room Only
                      </li>
                      <li>
                        No meals included
                      </li>
                      <li>
                        Free welcome drink
                      </li>
                    </ul>
                  </div>
                  <div className='Review-Booking-Section-Upgrade-con'>
                    <div className='Review-Booking-Section-Upgrade-Head'>
                      <h5>upgrade your stay</h5>
                    </div>
                    <div className='Review-Booking-Section-Upgrade-Options-con'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='Review-Booking-Section-Upgrade-Options'>
                            <input type="radio" id="html" name="fav_language" value="HTML" />
                            <label for="html">Add Breakfast for $20</label>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='Review-Booking-Section-Upgrade-Options'>
                            <input type="radio" id="html" name="fav_language" value="HTML" />
                            <label for="html">Add Breakfast + Lunch/Dinner for $20</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Review-Guest-Section-Details-Info-Main-con'>
                  <div className='Review-Guest-Section-Details-Info-Head'>
                    <h2>Guest Details</h2>
                  </div>
                  <div className='Review-Guest-Inputs-Section-Details-Info-con'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <div className='Review-Guest-Inputs-Section-Details-Info'>
                          <label for="Title">Title</label>
                          <select name="Title" id="Title" form="Titleform">
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-9'>
                        <div className='Review-Guest-Inputs-Section-Details-Info'>
                          <label for="fname">Full name:</label>
                          <div className='Review-Guest-Inputs-Section-Details-name-Input'>
                            <input type="text" id="fname" name="fname" placeholder='Enter your first Name' />
                            <input type="text" id="fname" name="fname" placeholder='Enter your last Name' />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='Review-Guest-Inputs-Section-Details-Info'>
                          <label for="fname">Email address:</label>
                          <div className='Review-Guest-Inputs-Section-Details-name-Input'>
                            <input type="text" id="fname" name="fname" placeholder='Enter your first Name' />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='Review-Guest-Inputs-Section-Details-Info'>
                          <label for="fname">Mobile number:</label>
                          <div className='Review-Guest-Inputs-Section-Details-name-Input'>
                            <input type="text" id="fname" name="fname" placeholder='Enter your first Name' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='Review-Guest-btn-Section-Details-Info'>
                      <button>
                        <FaPlus /> Add Guest
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='Review-Price-Section-Details-con'>
                  <div className='Review-Price-Section-Details-Head'>
                    <h2>Price Breakout</h2>
                  </div>

                  <div className='Review-Price-Section-Details-Info-con'>
                    <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                      <div className='Review-Price-Section-Details-Text'>
                        <h2>1 Room X 5 Night </h2>
                        <h3>Base Price</h3>
                      </div>
                      <div className='Review-Price-Section-Details-Number'>
                        <h4>$ 244</h4>
                      </div>
                    </div>

                    <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                      <div className='Review-Price-Section-Details-Text'>
                        <h2>Adult</h2>
                      </div>
                      <div className='Review-Price-Section-Details-Number'>
                        <h4>$ 70</h4>
                      </div>
                    </div>

                    <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                      <div className='Review-Price-Section-Details-Text'>
                        <h2>Child</h2>
                      </div>
                      <div className='Review-Price-Section-Details-Number'>
                        <h4>$ 31</h4>
                      </div>
                    </div>

                    <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                      <div className='Review-Price-Section-Details-Text'>
                        <h2>Taxes</h2>
                      </div>
                      <div className='Review-Price-Section-Details-Number'>
                        <h4>$ 20</h4>
                      </div>
                    </div>

                    <div className='Review-Price-Section-Details-Info'>
                      <div className='Review-Price-Section-Details-Text-Total'>
                        <h2>Total amount to be paid</h2>
                      </div>
                      <div className='Review-Price-Section-Details-Number-Total'>
                        <h4>$ 420</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='Review-Price-Section-Details-con'>
                  <div className='Review-Price-Section-Details-Head'>
                    <h2>Price Breakout</h2>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum, praesentium?</p>
                  </div>
                  <div className='Review-Price-Section-Details-coupon-Con'>
                    <input placeholder='Please enter your code' />
                    <button>
                      <MdKeyboardArrowRight />
                    </button>
                  </div>
                </div> */}
                <div className='Booking-btn-Con'>
                  <Link to="/pay">
                    <button>
                      Pay Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterSec />
    </>
  )
}

export default ReviewBooking