import React, { createContext, useContext, useState } from 'react';

const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adultQuantity, setAdultQuantity] = useState(1);
  const [childrenQuantity, setChildrenQuantity] = useState(0);
  const [roomsQuantity, setRoomsQuantity] = useState(1);

  const values = {
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    adultQuantity,
    setAdultQuantity,
    childrenQuantity,
    setChildrenQuantity,
    roomsQuantity,
    setRoomsQuantity,
  };

  return <BookingContext.Provider value={values}>{children}</BookingContext.Provider>;
};