import React from 'react';
import "../Rooms/RoomCard.css";
import { FaStar } from "react-icons/fa6";
import { LuScale3D } from "react-icons/lu";
import { IoWifi } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";

import Room01 from "../../Images/About/9T0A0367.JPG"
import HomeRoomData from '../HomeRoom/HomeRoomData';
import RoomData from "../RoomDetails/RoomData";
import { Link } from 'react-router-dom';

const RoomCard = ({ roomData }) => {
    return (
        <>

            <section className='Rooms-Page-Section-Card-con'>
                <div className='container'>
                    <div className='Rooms-Page-Section-Card'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='Room-Page-Section-card-Image'>
                                    <img src={roomData.RoomMainImage} alt='' />
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='Room-Page-Section-Card-Details-con'>

                                    <div className='Room-Page-Card-Name-And-Reviews-Card'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-card-Name-And-Star-con'>
                                                    <h2>{roomData.RoomName}</h2>
                                                    <div className='Room-Page-card-Star-con'>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star'>
                                                            <FaStar />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-Card-reviews-Con'>
                                                    <div className='Rooms-page-Card-reviews-Number'>
                                                        <h3>Very Good</h3>
                                                        <h4>{roomData.Reviews} review</h4>
                                                    </div>
                                                    <div className='Rooms-page-Card-reviews-Number-Rate'>
                                                        <h5>{roomData.ReviewsNumber}</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='Rooms-page-card-Details-Para'>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias officia ab mollitia saepe sint quae esse architecto, aliquid maxime, rem at totam ex libero aliquam veniam voluptatibus, voluptas molestiae perferendis.Molestias officia ab mollitia saepe sint quae esse architecto, aliquid maxime, rem at totam ex libero aliquam veniam voluptatibus, voluptas molestiae perferendis.</p>
                                    </div>

                                    <div className='Rooms-page-card-RoomsDetails'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <IoWifi />
                                                                <h6>inclusive wifi</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaRegUser />
                                                                <h6>Adult 3</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <LuScale3D />
                                                                <h6>{roomData.RoomSize}</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Queen Bed</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-Card-Price-Con'>
                                                    <div className='Room-Page-Card-Main-Price-con'>
                                                        <h6 className='Room-Page-Card-Main-Price'>$ {roomData.Price} / Night</h6>
                                                    </div>
                                                </div>
                                                <div className='Room-Page-Card-Offer-Price-Con'>
                                                    <div className='Room-Page-Card-Offer-Price-Btn'>
                                                        <button>
                                                            Offer Price
                                                        </button>
                                                    </div>
                                                    <div className='Room-Page-Card-Offer-Price'>
                                                        <h6> $ {roomData.OfferPerice} / Night</h6>
                                                    </div>
                                                </div>
                                                <div className='Room-Page-card-book-Btn'>
                                                    <Link to="/RoomDetailsPage">
                                                        <button>
                                                            Book Now
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default RoomCard