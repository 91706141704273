import React from 'react';
import "../Pay/LikePay.css";
import card from "../../Images/Pay Icons/credit-card (1).png";
import UPI from "../../Images/Pay Icons/google-pay.png";
import Gpay from '../../Images/Pay Icons/google-pay.png';
import netBank from "../../Images/Pay Icons/bank.png";
import Cash from "../../Images/Pay Icons/wallet.png";
import { AiOutlineUser } from "react-icons/ai";
import DemoRoom from "../../Images/About/9T0A0367.JPG";
import FooterSec from '../Footer/FooterSec';
import { Link } from 'react-router-dom';

const LikePay = () => {
    return (
        <>
            <section className='Like-To-Pay-Section-main-con'>
                <div className='container'>
                    <div className='Like-To-Pay-Section-con'>
                        <div className='Like-To-Pay-Section-Head-con'>
                            <h2>How would you like to pay?</h2>
                        </div>
                        <div className='Like-To-Pay-Section-Head-main-con'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='Like-To-Pay-Option-Con'>
                                        <div className='Like-To-Pay-Options'>
                                            <input type="radio" id="html" name="fav_language" value="HTML" />
                                            <div className='Like-To-Pay-Options-Image-con'>
                                                <div className='Like-To-Pay-Options-Image'>
                                                    <img src={card} />
                                                </div>
                                                <label for="html">Credit/Debit/ATM Card</label>
                                            </div>
                                        </div>
                                        <div className='Like-To-Pay-Options'>
                                            <input type="radio" id="html" name="fav_language" value="HTML" />
                                            <div className='Like-To-Pay-Options-Image-con'>
                                                <div className='Like-To-Pay-Options-Image'>
                                                    <img src={UPI} />
                                                </div>
                                                <label for="html">UPI Options</label>
                                            </div>
                                        </div>
                                        <div className='Like-To-Pay-Options'>
                                            <input type="radio" id="html" name="fav_language" value="HTML" />
                                            <div className='Like-To-Pay-Options-Image-con'>
                                                <div className='Like-To-Pay-Options-Image'>
                                                    <img src={Gpay} />
                                                </div>
                                                <label for="html">GooglePay</label>
                                            </div>
                                        </div>
                                        <div className='Like-To-Pay-Options'>
                                            <input type="radio" id="html" name="fav_language" value="HTML" />
                                            <div className='Like-To-Pay-Options-Image-con'>
                                                <div className='Like-To-Pay-Options-Image'>
                                                    <img src={netBank} />
                                                </div>
                                                <label for="html">Net Banking</label>
                                            </div>
                                        </div>
                                        <div className='Like-To-Pay-Options'>
                                            <input type="radio" id="html" name="fav_language" value="HTML" />
                                            <div className='Like-To-Pay-Options-Image-con'>
                                                <div className='Like-To-Pay-Options-Image'>
                                                    <img src={Cash} />
                                                </div>
                                                <label for="html">Cash</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='Like-to-pay-form-con'>
                                        <div className='Like-to-pay-form-con-Card-Info'>
                                            <label for="fname">Card number</label>
                                            <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                <input type="text" id="fname" name="fname" placeholder='Enter your card number' />
                                            </div>
                                        </div>

                                        <div className='Like-to-pay-form-con-Card-date-cvv'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='Like-to-pay-form-con-Card-Info'>
                                                        <label for="fname">Expire date</label>
                                                        <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                            <input type="text" id="fname" name="fname" placeholder='Enter expire date' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='Like-to-pay-form-con-Card-Info'>
                                                        <label for="fname">CVV</label>
                                                        <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                            <input type="text" id="fname" name="fname" placeholder='Enter CVV number' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Like-to-pay-form-con-Card-Info'>
                                            <label for="fname">Name on card</label>
                                            <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                <input type="text" id="fname" name="fname" placeholder='Enter your name' />
                                            </div>
                                        </div>

                                        <div className='Like-to-Pay-Form-btn-con'>
                                            <Link to="/BookingConfirm">
                                                <button>
                                                    Confirm payment
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='Like-to-pay-form-con'>
                                        <div className='Like-to-pay-Room-Details-con-Image'>
                                            <img src={DemoRoom} alt='' />
                                        </div>

                                        <div className='Like-to-pay-Room-Details-con-Info'>
                                            <div className='Like-to-pay-Room-Info-con'>
                                                <h2>Classic room</h2>
                                                <h3>Room only</h3>
                                            </div>
                                            <div className='Like-to-pay-Room-Booking-DateInfo-con'>
                                                <div className='Like-to-pay-Booking-Date-con'>
                                                    <h2>Check-In</h2>
                                                    <h3>Fri,22 Des 2024</h3>
                                                </div>
                                                <h5>-</h5>
                                                <div className='Like-to-pay-Booking-Date-con'>
                                                    <h2>Check-Out</h2>
                                                    <h3>Fri,22 Des 2024</h3>
                                                </div>
                                            </div>
                                            <div className='Like-to-pay-Room-cust-DateInfo-con'>
                                                <div className='Like-to-pay-Room-cust-Date-Info'>
                                                    <AiOutlineUser />
                                                    <h4>Travelers (s)</h4>
                                                </div>
                                                <div className='Like-to-pay-Room-cust-Info-Details'>
                                                    <h5>1. John Doe</h5>
                                                    <h6>Johndoe@yahoo.com</h6>
                                                    <h6>+09 87767 75325</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='Review-Price-Section-Details-con'>
                                            <div className='Review-Price-Section-Details-Head'>
                                                <h2>Summary</h2>
                                            </div>

                                            <div className='Review-Price-Section-Details-Info-con'>
                                                <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                                                    <div className='Review-Price-Section-Details-Text'>
                                                        <h2>1 Room X 5 Night </h2>
                                                        <h3>Base Price</h3>
                                                    </div>
                                                    <div className='Review-Price-Section-Details-Number'>
                                                        <h4>$ 244</h4>
                                                    </div>
                                                </div>

                                                <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                                                    <div className='Review-Price-Section-Details-Text'>
                                                        <h2>Adult</h2>
                                                    </div>
                                                    <div className='Review-Price-Section-Details-Number'>
                                                        <h4>$ 70</h4>
                                                    </div>
                                                </div>

                                                <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                                                    <div className='Review-Price-Section-Details-Text'>
                                                        <h2>Child</h2>
                                                    </div>
                                                    <div className='Review-Price-Section-Details-Number'>
                                                        <h4>$ 31</h4>
                                                    </div>
                                                </div>

                                                <div className='Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border'>
                                                    <div className='Review-Price-Section-Details-Text'>
                                                        <h2>Taxes</h2>
                                                    </div>
                                                    <div className='Review-Price-Section-Details-Number'>
                                                        <h4>$ 20</h4>
                                                    </div>
                                                </div>

                                                <div className='Review-Price-Section-Details-Info'>
                                                    <div className='Review-Price-Section-Details-Text-Total'>
                                                        <h2>Total amount to be paid</h2>
                                                    </div>
                                                    <div className='Review-Price-Section-Details-Number-Total'>
                                                        <h4>$ 420</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterSec />
        </>
    )
}

export default LikePay