import React from 'react';
import "../Css/Contact.css";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";
import Footer from "../Component/Footer/FooterSec";
import Header from "../Component/Navbar/Header";

const Contact = () => {
  return (
    <>
      <Header />
      <section className='Contact-Page-Section-Banner-Images'>
        <div className='Contact-Page-Section-Banner-Images-con'>
          <h2>Contact us</h2>
        </div>
      </section>

      <section className='Contact-Page-Form-And-Details-con'>
        <div className='container'>
          <div className='Contact-Page-Details-con'>
            <div className='row'>
              <div className='col-md-7'>
                <div className='Contact-Page-Details-Info-con'>
                  <div className='Contact-Page-Details-Head-con'>
                    <h3>Contact with us</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, minima? Vitae enim natus est accusamus nisi qui perferendis aliquam hic? Autem, minima? Vitae enim natus est accusamus nisi qui perferendis aliquam hic?</p>
                  </div>

                  <div className='Contact-Page-Details-contact-con'>
                    <div className='Contact-Page-Details-Icons-Info-con'>
                      <IoIosCall />
                      <div className='Contact-Page-Details-Info'>
                        <h4>Call us now</h4>
                        <a href="tel:+919322210409" target='blank'>
                          <h5>+91 93222 10409</h5>
                        </a>
                      </div>
                    </div>

                    <div className='Contact-Page-Details-Icons-Info-con'>
                      <IoIosMail />
                      <div className='Contact-Page-Details-Info'>
                        <h4>Send email</h4>
                        <a href="mailto: suryasparadise@gmail.com" target='blank'>
                          <h5>suryasparadise@gmail.com</h5>
                        </a>
                      </div>
                    </div>

                    <div className='Contact-Page-Details-Icons-Info-con'>
                      <SiGooglemaps />
                      <div className='Contact-Page-Details-Info'>
                        <h4>Our location</h4>
                        <a href='https://maps.app.goo.gl/Ex4SfsHeuQWkSBiN9' target='blank'>
                          <h5>near, School, near Avakali, near Laxmi Strawberry Farm, Avakali, Mahabaleshwar, Maharashtra 412806</h5>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <section className='Contact-page-Form-Con'>
                  <div className='Contact-page-Form-Head-Con'>
                    <h2>Get in Touch</h2>
                  </div>
                  <div className='Contact-Page-Form-Inputs-con'>
                    <div className='Contact-Page-Form-Inputs'>
                      <label for="fname">Enter name</label>
                      <div className='Like-to-pay-form-con-Card-Info-Input'>
                        <input type="text" id="fname" name="fname" placeholder='Enter your name' />
                      </div>
                    </div>
                    <div className='Contact-Page-Form-Inputs'>
                      <label for="fname">Enter number</label>
                      <div className='Like-to-pay-form-con-Card-Info-Input'>
                        <input type="number" id="fname" name="fname" placeholder='Enter your phone number' />
                      </div>
                    </div>

                    <div className='Contact-Page-Form-Inputs'>
                      <label for="fname">Message</label>
                      <div className='Like-to-pay-form-con-Card-Info-Input'>
                        <textarea type="text" id="fname" name="fname" placeholder='Enter your message' />
                      </div>
                    </div>

                    <div className='Contact-Page-Form-button'>
                      <button>
                        Sent Message
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='Contact-Page-Map-Section'>
        <div className='container'>
          <div className='Contact-Page-Map-Section-con'>
            <div className='Contact-Page-Map-Head-Section-con'>
              <h2>View your location</h2>
            </div>

            <div className='Contact-Page-Map-Section-con-frem'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3796.128661459912!2d73.7099381!3d17.926152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc27b8ffcc60ea7%3A0xa8592acc789d2312!2sRoyal%20Resort%20Mahabaleshwar!5e0!3m2!1sen!2sin!4v1713512442438!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>          </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Contact